const initialState = {
  check: []
}

const WebSocketReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'CHECK_UPDATE_EVENT': {
      const check =
        state.check.length === 0
          ? [action.check]
          : state.check.map(check => {
              if (check.check_id === action.check.check_id) {
                return action.check
              }
              return check
            })
      return {
        ...state,
        isUpdated: false,
        check
      }
    }
    case 'CHECK_COMPLETE_EVENT': {
      const check =
        state.check.length === 0
          ? [action.check]
          : state.check.map(check => {
              if (check.check_id === action.check.check_id) {
                return action.check
              }
              return check
            })
      return {
        ...state,
        isUpdated: false,
        check
      }
    }

    default: {
      return { ...state }
    }
  }
}

export default WebSocketReducer
