import { combineReducers } from 'redux'
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import CrudLocReducer from './reducers/CrudLocReducer'
import CrudUserReducer from './reducers/CrudUserReducer'
import ProfileReducer from './reducers/ProfileReducer'
import WebSocketReducer from './reducers/WebSocketReducer'
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  rentals: CrudLocReducer,
  users: CrudUserReducer,
  profile: ProfileReducer,
  process: WebSocketReducer
})

export default rootReducer
