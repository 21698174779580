const initialState = {
  users: [],
  isUpdated: false,
  firstAccessPassword: {},
  password: {}
}

const CrudUserReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_USER': {
      return {
        ...state,
        // users: [...state.users, action.user],
        firstAccessPassword: action.firstAccessPassword
      }
    }
    case 'GET_ALL_USERS': {
      return { ...state, users: action.users, isUpdated: true }
    }
    case 'GET_USER': {
      return {
        ...state,
        users: [
          ...state.users.filter(user => user.username !== action.user.username),
          action.user
        ]
      }
    }
    case 'DELETE_USER': {
      return {
        ...state,
        users: state.users.filter(user => user.username !== action.username)
      }
    }
    case 'UPDATE_USER': {
      return {
        ...state,
        users: [
          ...state.users.filter(user => user.username !== action.user.username),
          action.user
        ]
      }
    }
    case 'RESET_PASSWORD': {
      return {
        ...state,
        password: {
          ...state.password,
          [action.user]: action.password
        }
      }
    }
    case 'UPLOAD_PEM': {
      return { ...state }
    }
    default: {
      return { ...state }
    }
  }
}

export default CrudUserReducer
